import React from 'react';
import {graphql, PageProps, Link} from 'gatsby';
import Layout from '../components/Layout';
import {Query} from '../../graphql-types';
import Helmet from 'react-helmet';
import logo from '../../static/logo-square-wob.png';

const MdPageTemplate: React.FC<PageProps<Query>> = ({data}) => {

  const post = data.markdownRemark;
  return (
    <Layout>
      <div className="max-w-screen-md mx-auto post">
        <Link to="/blog" className="block mb-2">
    &lt;&lt; Back to post list
        </Link>
        <h1>{post.frontmatter?.title}</h1>
        <span className="text-sm">{post.frontmatter?.date}</span>
        <hr className="my-4"/>
        <div dangerouslySetInnerHTML={{__html: post.html}}>
        </div>
      </div>
      <Helmet>
        <title>{post.frontmatter?.title}</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://riksolo.com/blog/${post.frontmatter?.slug}`} />
        <meta property="og:title" content={post.frontmatter?.title} />
        <meta property="og:description" content={post.frontmatter?.description} />
        <meta property="og:image" content={`https://riksolo.com${logo}`} />


        <meta name="title" content={post.frontmatter?.title} />
        {post.frontmatter.description ? (
          <meta name="description" content={post.frontmatter?.description} />
        ): null}
        <meta name="author" content="Rik Berkelder" />

        <script type="application/ld+json">
          {`{
    "@context": "http://schema.org/",
    "@type": "BlogPosting",
    "author": {
      "@type": "Person",
      "name": "Rik Berkelder"
    },
    "headline": "${post.frontmatter?.title}",
${post.frontmatter?.description ? '"description": "' + post.frontmatter?.description + '",' : ''}
    "datePublished": "2021-05-06"

          }`}
        </script>
      </Helmet>
    </Layout>
  );
}

export default MdPageTemplate;

export const pageQuery = graphql`
  query MarkdownPageTemplate($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
`;
